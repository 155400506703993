body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-program-button {
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  filter: grayscale(100%);
}
.material-program-button:hover {
  filter: grayscale(70%);
}

.material-program-button.selected {
  filter: grayscale(0%);
}

.material-info-form-image {
  width: 100%;
  height: 100%;
}

.material-info-form-image > div {
  width: 100%;
  height: 100%;
}

.material-info-form-image > div > div {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.material-info-form-image img {
  height: 80%;
}

.material-info-form-image p {
  width: 100%;
  height: 100%;
  position: relative;
  top: -200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tr-templates > div {
  filter: grayscale(80%);
  cursor: pointer;
}

.tr-templates > div:hover {
  filter: grayscale(0%);
}

.material-default-wrapper .tr-editor {
  color: #fff;
  background: transparent;
  width: 100%;
  margin-right: 90px;
  height: 100%;
  overflow: scroll;
}
.custom-upload-image p {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ButtonWrap__root___1EO_R {
  z-index: 1 !important;
}

.sidebar {
  z-index: 6 !important;
}

.family-pdf button {
  width: 100%;
}